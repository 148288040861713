import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useMuskPool } from '../../../hooks/useContract'
import { useSingleCallResult } from '../../../state/multicall/hooks'

const useLastDepositTime = () => {
  const { account } = useWeb3React()
  const muskPool = useMuskPool(false)
  const sender = useMemo(() => [account], [account])
  const userInfo = useSingleCallResult(muskPool, 'userInfo', sender).result
  const lastDepositedTime = useMemo(() => {
    if (!userInfo) {
      return '0'
    }
    return new BigNumber(userInfo.lastDepositTime._hex.toString()).toString()
  }, [userInfo])

  const userShares = new BigNumber(1000)
  const withdrawalFee = 20
  const withdrawalFeePeriod = 172800
  return {
    userData: { lastDepositedTime, userShares },
    fees: { withdrawalFee, withdrawalFeePeriod },
  }
}

export default useLastDepositTime
