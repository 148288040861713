import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { TransactionResponse } from '@ethersproject/providers'
import {
  Alert,
  alertVariants,
  Box,
  Button,
  CardBody,
  CopyIcon,
  Flex,
  IconButton,
  Input,
  Text,
} from '@pancakeswap/uikit'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import styled from 'styled-components'
import { parseEther } from 'ethers/lib/utils'
import { AutoColumn } from '../../components/Layout/Column'
import { AppBody } from '../../components/App'
import { useIdoContract, useTokenContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../../state/multicall/hooks'
import useToast from '../../hooks/useToast'


const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
  position: absolute;
  padding: 8px;
  top: -38px;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
  width: 100px;
`

export default function Ido({
  match: {
    params: { inviter },
  },
  history,
}: RouteComponentProps<{ inviter?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()

  const [roundIdo, setRoundIdo] = useState(1)
  const [valueInput, setValueInput] = useState('')
  const [rawValue, setRawValue] = useState(100e18)
  const usdt = {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x7afd064DaE94d73ee37d19ff2D264f5A2903bBB0',
  }

  const ido = useIdoContract(true)
  const token = useTokenContract(usdt[chainId])
  const roundInfo = useSingleCallResult(ido, 'roundInfo')?.result?._roundInfo
  const inputs1 = useMemo(() => [account], [account])
  const userInfo = useSingleCallResult(ido, 'getUserInfo', inputs1)?.result?._userInfo
  const inputs = useMemo(() => [account, ido.address], [account, ido.address])
  console.log(inputs)
  const approvedValue = useSingleCallResult(token, 'allowance', inputs).result
  console.log(`approvedValue =${approvedValue}`)
  console.log(approvedValue)

  const openClaim: any = useSingleCallResult(ido, 'openClaim')?.result

  const zeroAddr = '0x0000000000000000000000000000000000000000'
  // mark when a user has submitted an approval, reset onTokenSelection for input field

  const [claimValue, setClaimValue] = useState('0')
  const [canClaim, setCanClaim] = useState(false)

  useMemo(() => {
    if (!userInfo) {
      return
    }
    const buyValue = userInfo.buyValue
    const claimedValue = userInfo.claimedValue
    const _raleValue = new BigNumber(buyValue.toString()).minus(claimedValue.toString()).div(1e18)
    setClaimValue(_raleValue.toString())
  }, [userInfo])

  useMemo(() => {
    // console.log(roundInfo)
    if (roundInfo?.index) {
      setRoundIdo(roundInfo?.index?.toNumber())
      // console.log(roundIdo)
    }
  }, [roundInfo])

  const handlerToPay = useCallback(async () => {
    if (!account || !chainId || !library) {
      return
    }
    try {

      const value =  parseEther(valueInput);
      console.log(approvedValue)
      const _approvedValue = approvedValue[0];
      console.log(`_approvedValue = ${_approvedValue}`)
      if (BigNumber.isBigNumber(_approvedValue) && _approvedValue.lt(value._hex)) {
        const tx: TransactionResponse = await token.approve(
          ido.address,
          '115792089237316195423570985008687907853269984665640564039457584007913129639935',
        )
        const result = await tx.wait()
        if (result.status !== 1) {
          return
        }
      } else if (new BigNumber(_approvedValue).lt(value._hex)) {
        const tx: TransactionResponse = await token.approve(
          ido.address,
          '115792089237316195423570985008687907853269984665640564039457584007913129639935',
        )
        const result = await tx.wait()
        if (result.status !== 1) {
          return
        }
      }
      // const gasLimit: BigNumber | void = await ido.estimateGas["deposit"](...payArgs)
      const tx = await ido.deposit(inviter ?? zeroAddr, value._hex)
      const result = await tx.wait()
      if (result.status === 1) {
        console.log('buySuccess')
        toastSuccess('Pay Success')
      } else {
        console.log('buy failed')
        toastError('Pay Failed', 'Please Try again')
      }
    } catch (e) {
      console.log('error')

      console.log(e)
      if (e.data?.message) {
        console.log(e.data?.message)
        toastError('Pay Failed', e.data?.message)
      }
    }
  }, [account, chainId, library, valueInput, approvedValue, ido, inviter, token, toastSuccess, toastError])
  const handlerToClaim = useCallback(async () => {
    if (!account || !chainId || !library) {
      return
    }
    try {
      // const gasLimit: BigNumber | void = await ido.estimateGas["deposit"](...payArgs)
      const tx = await ido.claim()
      const result = await tx.wait()
      if (result.status === 1) {
        console.log('Claim Success')
        toastSuccess('Claim Success')
      } else {
        console.log('Claim failed')
        toastError('Claim Failed', 'Please Try again')
      }
    } catch (e) {
      console.log('error')

      console.log(e)
      if (e.data?.message) {
        console.log(e.data?.message)
        toastError('Claim Failed', e.data?.message)
      }
    }
  }, [account, chainId, library, ido, toastSuccess, toastError])

  function parseCustomValue(value: string) {
    if (value === '') {
      setValueInput('')
    } else {
      const newValue = Number.parseFloat(value).toFixed(0)
      setValueInput(newValue)
    }

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 1e18).toString())
      if (
        !Number.isNaN(valueAsIntFromRoundedFloat) &&
        valueAsIntFromRoundedFloat >= 100e18 &&
        valueAsIntFromRoundedFloat <= 500e18
      ) {
        setRawValue(valueAsIntFromRoundedFloat)
      } else if (valueAsIntFromRoundedFloat > 500e18) {
        setRawValue(500e18)
      } else {
        setRawValue(100e18)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // useMemo(() => {
  //   if (roundIdo === 0) {
  //
  //     if (valueInput === '') {
  //
  //       setValueInput('200')
  //       setRawValue(200e18)
  //     }
  //   } else if (roundIdo === 1) {
  //     if (valueInput === '') {
  //       setValueInput('100')
  //       setRawValue(100e18)
  //     }
  //
  //   } else if (valueInput === '') {
  //       setValueInput('')
  //       setRawValue(0)
  //
  //   }
  // }, [roundIdo, valueInput])
  const [formatInviteUrl, setFormatInviteUrl] = useState("https://www.muskswap.monster/ifo/")
  useEffect(() => {
    if (!account) {
      return
    }
    const u = `https://www.muskswap.monster/ifo/${account}`
    setFormatInviteUrl(u)
  }, [account])

  useEffect(() => {
    if (openClaim) {
      setCanClaim(openClaim[0])
    }
  }, [openClaim, canClaim])

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  function displayTooltip() {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }

  const copyAddress = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(formatInviteUrl).then(() => displayTooltip())
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = formatInviteUrl
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '100px',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <Text>IFO</Text>
      <div style={{ height: '10px' }} />
      <AppBody>
        <Box pt="20px">
          <Box height="20px" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '30px',
              padding: '10px',
            }}
          >
            <Box width="100%">
              <Flex>
                <Flex
                  style={{
                    width: '100%',
                    background: '#372F47',
                    borderRadius: '16px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <input
                    style={{
                      background: 'transparent',
                      border: 'none',
                      outline: 'none',
                      color: '#F4EEFF',
                      flex: 1,
                      fontSize: '16px',
                    }}
                    placeholder="最低100，最多500"
                    value={valueInput}
                    onBlur={() => {
                      parseCustomValue((rawValue / 1e18).toFixed(0))
                    }}
                    onChange={(e) => parseCustomValue(e.target.value)}
                  />
                  <Flex alignItems="center">
                    <Text style={{ color: '#F4EEFF59' }}>USDT</Text>
                  </Flex>
                </Flex>
                <div style={{ width: '10px' }} />
                <Button style={{ width: '100px' }} onClick={handlerToPay}>
                  支付
                </Button>
              </Flex>
              <div style={{ height: '20px' }} />
              <Text>邀请链接：</Text>
              <div style={{ wordBreak: 'break-word' }}>{formatInviteUrl}</div>
              <Flex justifyContent="center">
                <IconButton variant="text" onClick={copyAddress}>
                  <Flex alignItems="center">
                    <div style={{ width: '40px' }}>复制</div>
                    <CopyIcon color="primary" width="24px" />
                  </Flex>
                </IconButton>
              </Flex>
              <Flex alignItems="center" pt="20px">
                <Text>可提取：{claimValue}</Text>
                <Box style={{ flex: 1 }} />
                {canClaim ? <Button onClick={handlerToClaim}>提取</Button> : <Button disabled>提取</Button>}
              </Flex>
            </Box>
          </div>
        </Box>
        <CardBody>
          <AutoColumn gap="20px" />
        </CardBody>
      </AppBody>
    </div>
  )
}
